import { useState } from 'react'
import { NavLink } from 'react-router-dom'

function NavHeader() {
  const [mobileNavIsOpen, setIsOpen] = useState(false)
  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <h1 className="hidden md:block h-8 w-auto text-2xl text-gray-300 not-italic">Speedrun.stream</h1>
              <h1 className="block md:hidden h-8 w-auto text-2xl text-gray-300 italic">S.s</h1>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <NavLink exact to="/" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" activeClassName="ring-2 ring-gray-300">Highlights</NavLink>
                <NavLink to="/rpglb" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" activeClassName="ring-2 ring-gray-300">RPG Limit Break</NavLink>
                <NavLink to="/about" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" activeClassName="ring-2 ring-gray-300">About</NavLink>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex sm:hidden">
            {/* <!-- Mobile menu button --> */}
            <button onClick={() => setIsOpen(!mobileNavIsOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              {/* <!-- Icon when menu is closed. -->
              <!--
                Heroicon name: menu

                Menu open: "hidden", Menu closed: "block"
              --> */}
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={mobileNavIsOpen} />
    </nav>
  )
}

function MobileMenu({ isOpen }) {
  return (
    <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`}>
      <div className="px-2 pt-2 pb-3 space-y-1">
        <NavLink exact to="/" className="text-white hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium" activeClassName="ring-2 ring-gray-300">Highlights</NavLink>
        <NavLink to="/rpglb" className="text-white hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium" activeClassName="ring-2 ring-gray-300">RPG Limit Break</NavLink>
        <NavLink to="/about" className="text-white hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium" activeClassName="ring-2 ring-gray-300">About</NavLink>
      </div>
    </div>
  )
}

export default NavHeader