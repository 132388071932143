import {
  Switch,
  Route,
  NavLink,
  useRouteMatch
} from 'react-router-dom'

import Topic from './Topic'

function Topics() {
  let { path } = useRouteMatch()

  return (
    <div className="space-y-2">
      <EventSelector />
      <Switch>
        <Route exact path={path}>
          <h4>Please select a year.</h4>
        </Route>
        <Route path={`${path}/:eventId`}>
          <Topic />
        </Route>
      </Switch>
    </div>
  )
}

function EventSelector() {
  let { url } = useRouteMatch()
  return (
    <div className="block">
      <nav className="flex space-x-4" aria-label="Tabs">
        <NavLink to={`${url}/2019`} className="text-gray-600 hover:bg-gray-200 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md" activeClassName="ring-2 ring-gray-500">
          2019
        </NavLink>
        <NavLink to={`${url}/2018`} className="text-gray-600 hover:bg-gray-200 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md" activeClassName="ring-2 ring-gray-500">
          2018
        </NavLink>
        {/* <!-- Current: "bg-gray-200 text-gray-800", Default: "text-gray-600 hover:text-gray-800" --> */}
        <NavLink to={`${url}/2017`} className="text-gray-600 hover:bg-gray-200 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md" activeClassName="ring-2 ring-gray-500">
          2017
        </NavLink>
        <NavLink to={`${url}/2016`} className="text-gray-600 hover:bg-gray-200 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md" activeClassName="ring-2 ring-gray-500">
          2016
        </NavLink>
        <NavLink to={`${url}/2015`} className="text-gray-600 hover:bg-gray-200 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md" activeClassName="ring-2 ring-gray-500">
          2015
        </NavLink>
      </nav>
    </div>
  )
}

export default Topics;