function AboutPage() {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="prose lg:prose-xl">
        <h4>
          What is Speedrun.stream?
        </h4>
        <p>
          A small site I built in order to (in the short-term) learn me some React and to (in the longer-term) hopefully draw attention to some dope speedrun charity marathons.
        </p>
        <h4>
          Who made this garbage?
        </h4>
        <p>
          I'm James, a dude from Chicagoland who likes typing on mechanical keyboards and coding in virtually any programming language that isn't JavaScript.
          I have two black labs and a cat who might hate me.
        </p>
        <h4>
          When will your site support *insert basic accessability/usability/etc feature here* ?
        </h4>
        <p>
          Very soon, I hope. I just needed to get the site online before I went insane and/or my parents started asking what I've been doing for the last three months. You can check the current roadmap at {/*  TODO: can the github tool for this be set to public hosting? */}
        </p>
        <h4>
          How can I contribute?
        </h4>
        <p>
          Right now, I'm mostly looking for help with listing more events. For RPG Limit Break, I did all the data entry by hand, and I don't wanna do that again. If you can collect the data I need in a CSV format or help write a script to scrape the necessary data, reach out to me twitter.com/speedrun_stream {/* TODO: register this twitter before launch */}
        </p>
        <h4>
          There's an error in your data, how can I get it fixed?
        </h4>
        <p>
          DM me at @speedrun_stream with the error. Let me know if it's an important change (eg. runner's name in the DB is associated with their deadname) and I'll get on it ASAP.
        </p>
      </div>
    </div>
  )
}

export default AboutPage;