function EventTable(props) {
  const rows = props.rows
  return (
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Game
                  </th>
                  <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Runner
                  </th>
                  <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event
                  </th>
                  <th scope="col" class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  <th scope="col" class="px-6 py-3 bg-gray-50">
                    <span class="sr-only">Watch</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                {rows.map( run => 
                  <tr>
                    <td class="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">
                      {run.game}
                    </td>
                    <td class="px-6 py-4 whitespace-normal text-sm text-gray-500">
                      {run.runner}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {run.eventName}
                    </td>
                    <td class="px-6 py-4 whitespace-normal text-sm text-gray-500">
                      {run.category}
                    </td>
                    <td class="px-6 py-4 whitespace-normal text-right text-sm font-medium">
                      <a href={run.link} class="text-red-600 hover:text-indigo-900">Watch</a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventTable;