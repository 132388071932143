const highlights = [
  {
    game: 'Super Mario RPG: Legend of the Seven Stars',
    runner: 'pidgezero_one',
    eventName:'RPG LB 2019',
    category:'100% (Most of the Stuff)',
    link:'https://www.youtube.com/watch?v=3RY78mYRqbU',
  },
  {
    game: 'Robotrek',
    runner: 'PJ',
    eventName:'RPG LB 2016',
    category:'Any%',
    link:'https://www.youtube.com/watch?v=JdjjrMa3VvU',
  },
  {
    game: 'Super Hydlide',
    runner: 'mikwuyma',
    eventName:'RPG LB 2019',
    category:'Joke%',
    link:'https://www.youtube.com/watch?v=7i2-Knz_V5U',
  },
  {
    game: 'Terranigma',
    runner: 'BOWIEtheHERO',
    eventName:'RPG LB 2017',
    category:'Any%',
    link:'https://www.youtube.com/watch?v=gijoUJzaRvU',
  },
  {
    game: 'Crystalis',
    runner: 'Dragondarch',
    eventName:'RPG LB 2019',
    category:'Any% No Wild Warp',
    link:'https://www.youtube.com/watch?v=rfwIdB-aM-s',
  },
  {
    game: 'Hoshi wo Miru Hito (Stargazers)',
    runner: 'dowolf',
    eventName:'RPG LB 2019',
    category:'Any% (1p2c)',
    link:'https://www.youtube.com/watch?v=Y23gHrGsfTU',
  },
  {
    game: 'Paper Mario',
    runner: 'AndyPerfect, Drifting Skies',
    eventName:'RPG LB 2017',
    category:'Glitchless Race',
    link:'https://www.youtube.com/watch?v=9ypMPnDzTJ0',
  },
  {
    game: 'Chrono Cross',
    runner: 'EvilAsh25, spikevegeta',
    eventName:'RPG LB 2016',
    category:'Any%',
    link:'https://www.youtube.com/watch?v=5hnoU504HGo',
  },
  {
    game: 'Pokémon Crystal',
    runner: 'Keizaron',
    eventName:'RPG LB 2018',
    category:'Any% Glitchless',
    link:'https://www.youtube.com/watch?v=Hv1y7BhqExo',
  },
  {
    game: 'Tales of Phantasia',
    runner: 'Yagamoth',
    eventName:'RPG LB 2018',
    category:'Any%',
    link:'https://www.youtube.com/watch?v=p6_e88KwwR0',
  },
  {
    game: 'Final Fantasy',
    runner: 'DrangoAC, TheAxeMan',
    eventName:'RPG LB 2019',
    category:'No Memory Corruption No Reset TAS',
    link:'https://www.youtube.com/watch?v=TIjJ3Ukcg_4',
  }
]

export default highlights;