import {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import entryService from '../services/events'

import EventTable from './EventTable'

function Topic() {
  let {eventId} = useParams()
  const [tableEntries, setEntries] = useState([])
  useEffect(() => {
    entryService
      .getEvent('rpglb', eventId)
      .then(runs => {
        setEntries(runs)
      })
  }, [eventId])

  return (
    <EventTable rows={tableEntries} />
  )
}

export default Topic;