import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import NavHeader from './components/NavHeader'
import Topics from './components/Topics'
import Home from './components/Home'
import About from './components/About'
import Footer from './components/Footer'

function App() {
  return (
    <Router>
      <div className="space-y-2">
        <NavHeader />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/rpglb">
            <Topics />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
        <Switch>
          <Route exact path="/rpglb" />
          <Route path="/">
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
