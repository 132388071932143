import axios from 'redaxios'
const baseUrl = '/api'

/*
const getAll = () => {
  const request = axios.get(baseUrl + '/entries')
  return request.then(response => response.data)
}

const getSeries = (series) => {
  const request = axios.get(`${baseUrl}/series/${series}`)
  return request.then(response => response.data)
}

*/

function getEvent(series, year) {
  const request = axios.get(`${baseUrl}/series/${series}/event/${year}`)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log('Error', error.message)
      }
    })
  return request.then(response => response.data)
}

// eslint-disable-next-line
export default { getEvent }

